import {IConfig} from "@/config/types";

const config: IConfig = {
  version: "v1.2.0",
  project: "mall",
  httpPrefix: "/api",
  timeOut: 60 * 60 * 1000,
  platformName: "蜗牛优选管理平台",
  company: "深圳市榆晟科技有限责任公司",
  uploadUrl: "/party/oss/ordinaryUpload",
  fragmentationUrl: "/party/oss/burstUpload",
  downloadUrl: "",
  videoDownloadUrl:'',
  rootPhones: [
    '18360473809'
  ]
}

export default config
