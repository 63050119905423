
/**
 * @name: 自定义图片上传
 * @author: itmobai
 * @date: 2023-09-01 09:15
 * @description：自定义图片上传
 * @update: 2023-09-01 09:15
 */
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import draggable from "vuedraggable"
import config from "@/config"
import store from "@/store"
import ajax from "./ajax"
import { IFile } from "./types"

@Component({name: 'CusPictureUpload', components: {draggable}})
export default class CusPictureUpload extends Vue {
  // 禁用启用状态
  @Prop({default: false}) disabled!: boolean;
  // 文件名
  @Prop({default: 'file'}) name!: string;
  // 是否多选
  @Prop({default: false}) multiple!: boolean;
  // 文件类型
  @Prop(String) accept!: string;
  // 上传数量限制
  @Prop({default: 5}) limit!: number;
  // 文件列表
  @Prop(String) value!: string;
  // 文件超出个数限制
  @Prop(Function) onExceed!: Function;
  // 文件上传前
  @Prop(Function) beforeUpload!: Function;
  // 类型
  @Prop({default: 1}) type!: number;

  /**
   * 文件列表
   */
  fileList: IFile[] = []

  /**
   * 上传列表
   */
  reqs: any = []

  @Watch("value", {immediate: true})
  onValueChange (newVal: string, oldVal: string) {
    if (newVal == oldVal) {
      console.log(1111)
      return;
    }
    if (newVal) {
      this.fileList = newVal.split(';').map(item => {
        return {
          uid: new Date().getTime() + Number(Math.random() * 10),
          name: '',
          file: null,
          url: config.downloadUrl + item,
          rate: 100,
          type: "success"
        }
      })
    } else {
      this.fileList = []
    }
  }

  @Watch("fileList", {deep: true})
  onFileListChange (newVal: IFile[]) {
    if (!newVal || !newVal.length) {
      this.$emit('input', '')
    } else {
      let arr = newVal.map(item => {
        if (item.type == 'success') {
          return item.url.replace(config.downloadUrl, '')
        }
      })
      this.$emit('input', arr.join(';'))
    }
  }

  /**
   * 文件选择变化
   */
  handleChange (ev: any) {
    const files = ev.target.files;

    if (!files) return;
    this.uploadFiles(files);
  }

  uploadFiles (files: File[]) {
    if (this.limit && this.fileList.length + files.length > this.limit) {
      this.onExceed && this.onExceed(files, this.fileList);
      return;
    }

    let postFiles = Array.prototype.slice.call(files);
    if (!this.multiple) { postFiles = postFiles.slice(0, 1); }

    if (postFiles.length === 0) { return; }
    let oldLen = this.fileList.length
    postFiles.forEach((rawFile, rawIndex) => {
      this.handleStart(rawFile);
      this.upload(oldLen + rawIndex);
    });
  }


  /**
   * 文件开始准备
   */
  handleStart (file: File) {
    let fileItem: IFile = {
      uid: new Date().getTime() + Number(Math.random() * 10),
      name: file.name,
      url: URL.createObjectURL(file),
      file,
      type: 'ready',
      rate: 0
    }
    this.fileList.push(fileItem)
  }

  /**
   * 上传进度变化
   */
  handleProgress (e: any, index: number) {
    const file = this.fileList[index]
    this.$set(this.fileList, index, {...file, type: 'uploading', rate: Math.floor(e.percent)})
  }

  /**
   * 上传成功
   */
  handleSuccess (e: any, index: number) {
    const file = this.fileList[index]
    if (e && e.code == 0) {
      this.$set(this.fileList, index, {...file, type: 'success', url: config.downloadUrl + e.data.url})
    }
  }

  handleError (e: any, index: number) {
    const file = this.fileList[index]
    this.$set(this.fileList, index, {...file, type: 'error'})
    this.fileList.splice(index, 1)
  }

  /**
   * 上传
   */
  upload (index: number) {
    // @ts-ignore
    this.$refs.input.value = null;
    if (!this.beforeUpload) {
      return this.post(index);
    }

    const before = this.beforeUpload(this.fileList[index].file);
    if (before !== false) {
      this.post(index)
    } else {
      this.fileList.splice(index, 1)
    }
  }

  post (index: number) {
    const rawFile = this.fileList[index]
    const options: any = {
      headers: {
        'Authorization': `Bearer ${store.getters.token}`,
      },
      file: rawFile.file,
      data: {
        type: this.type
      },
      filename: 'file',
      action: '/api' + config.fragmentationUrl,
      onProgress: (e: any) => {
        this.handleProgress(e, index)
      },
      onSuccess: (res: any) => {
        this.handleSuccess(res, index);
        delete this.reqs[index];
      },
      onError: (err: any) => {
        this.handleError(err, index);
        delete this.reqs[index];
      }
    }
    const req: any = ajax(options)
    this.reqs[index] = req
    if (req && req.then) {
      req.then(options.onSuccess, options.onError);
    }
  }

  /**
   * 选择文件
   */
  handleClick () {
    if (!this.disabled) {
      // @ts-ignore
      this.$refs.input.value = null;
      // @ts-ignore
      this.$refs.input.click();
    }
  }

  /**
   * 移除文件
   */
  handleRemove (file: IFile) {
    let index = this.fileList.findIndex(item => item.uid === file.uid)
    this.fileList.splice(index, 1)
  }
}
