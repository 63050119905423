import { getPermission } from "@/apis/core"

const permission = {
  namespace: true,
  state: {
    // 权限数组
    permissionList: []
  },
  getters: {
    /**
     * 获取权限对象
     * @param state
     * @returns
     */
    permissions: (state: any) => (data: any) => {
      const result: any = {}
      for (let key in data) {
        if (state.permissionList.includes(data[key])) {
          result[key] = true
        } else {
          result[key] = false
        }
      }
      return result
    },
    /**
     * 是否拥有权限
     * @param state
     * @returns
     */
    hasPermission: (state: any) => (key: string) => {
      return state.permissionList.includes(key)
    }
  },
  mutations: {
    /**
     * 设置权限
     * @param state
     * @param data
     */
    SET_PERMISSION(state: any, data: string[]) {
      state.permissionList = data
    }
  },
  actions: {
    /**
     * 获取所有按钮权限
     * @param ctx
     */
    loadPermission(ctx: any) {
      return new Promise(resolve => {
        getPermission().then(e => {
          ctx.commit("SET_PERMISSION", e)
          resolve(null)
        })
      })
    }
  }
}

export default permission;
