/**
 * @name: 全局组件注册
 * @author: itmobai
 * @date: 2023-03-17 17:27
 * @description：全局组件注册
 * @update: 2023-03-17 17:27
 */
import Vue from "vue"
// 批量读取组件文件夹
import FileUpload from "./components/FileUpload/FileUpload.vue";
import Map from "./components/Map/Map.vue";
import PictureUpload from "./components/PictureUpload/PictureUpload.vue";
import VideoUpload from "./components/VideoUpload/VideoUpload.vue";
import WangEditor from "./components/WangEditor/WangEditor.vue";
import CusPictureUpload from "./components/CusPictureUpload"

Vue.component('FileUpload', FileUpload)
Vue.component('Map', Map)
Vue.component('PictureUpload', PictureUpload)
Vue.component('VideoUpload', VideoUpload)
Vue.component('WangEditor', WangEditor)
Vue.component('CusPictureUpload', CusPictureUpload)
