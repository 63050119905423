const menusList: any = [
  {
    "id": "111101",
    "pid": "1",
    "name": "addOrEditShelves",
    "menuType": "C",
    "icon": null,
    "component": "/product/add-edit-shelves",
    "path": "/product/add-edit-shelves",
    "sort": 1,
    "isHide": true,
    "isKeepAlive": false,
    "addTime": "2024-05-13 18:55:07",
    "meta": {
      "title": "商品新增/编辑",
      "isLink": null,
      "isHide": true,
      "isKeepAlive": false,
      "isAffix": false,
      "isIframe": false,
      "auth": "admin",
      "icon": null
    },
    "title": "商品新增/编辑",
    "children": []
  }
];
export default menusList;
