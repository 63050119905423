/**
 * @name: 自定义上传图片入口
 * @author: itmobai
 * @date: 2023-03-21 17:06
 * @description：自定义上传图片入口
 * @update: 2023-03-21 17:06
 */
import CusPictureUpload from "./CusPictureUpload.vue"
import Vue from "vue";

// @ts-ignore
CusPictureUpload.install = (vue: Vue) => {
  Vue.component(CusPictureUpload.name, CusPictureUpload);
}

export default CusPictureUpload;
