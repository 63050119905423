/**
 * @name: 图片上传组件入口
 * @author: itmobai
 * @date: 2023-03-17 17:25
 * @description：index
 * @update: 2023-03-17 17:25
 */
import PictureUpload from "./PictureUpload.vue"
import Vue from "vue";

// @ts-ignore
PictureUpload.install = (vue: Vue) => {
  Vue.component(PictureUpload.name, PictureUpload);
}

export default PictureUpload;
